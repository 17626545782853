<template>
        <div class="form-list">
            <el-form ref="_importSaleOrderForm" :model="dataSource">
                <el-form-item>
                    <el-col :span="21">  1、1点击<el-button type="text" @click="DownloadFile()" style="text-decoration:underline;color:#1874CD;cursor:pointer">这里</el-button>下载导入模板 </el-col>
                </el-form-item>
                <el-form-item>
                    <!--:beforeUpload="beforeUpload"-->
                    <el-upload class="upload-demo"
                               :action="importAction"
                               :on-preview="handlePreview"
                               :on-remove="handleRemove"
                               :before-remove="beforeRemove"
                               :data="upLoadData"
                               multiple
                               :limit="1"
                               :on-exceed="handleExceed"
                               :beforeUpload="beforeUpload"
                               :onSuccess="uploadSuccess"
                               :file-list="fileList"
                               accept=".xls,.xlsx"
                               :on-error="uploadError">
                        <el-button size="small" type="primary" :loading="loading" :disabled="loading">{{updateText}}</el-button>
                        <div slot="tip" class="el-upload__tip" style="overflow-y:auto;"><span v-text="tipmsg"></span></div>
                    </el-upload>
                </el-form-item>
            </el-form>
        </div>
</template>

<script>
    export default {
        data() {
            return {
                importAction: this.Utils.getDomain() + "omsapi/customeraddress/importcustomeraddress?currentType=" + this.$store.state.global.currentType + "&currentCCode=" + this.$store.state.global.currentCCode,
                tipmsg: "",
                fileList: [],
                upLoadData: {
                    AddressType:""
                },
                loading: false,
                updateText:"导入"
                
            };
        },
        props: {
            addressType: 0,
        },
        mounted() {
            this.initshow();
        },
        methods: {
            initshow()
            {
                this.upLoadData.AddressType = this.addressType;
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                if (file && file.status==="success")
                    return this.$confirm(`确定移除 ${file.name}？`);
            },
            // 上传成功后的回调
            uploadSuccess(response, file, fileList) {
                this.uploadTHint(false);
                console.log(response);
                this.tipmsg = response.OperationDesc;
                //this.Utils.messageBox(response.OperationDesc, response.IsSuccess ? "success" : "error");
            },
            // 上传前对文件的大小的判断
            beforeUpload(file) {
                this.uploadTHint(true);
                var extension = file.name.substring(file.name.lastIndexOf('.') + 1);
                var allowExtension = ['xls','xlsx'];
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (allowExtension.indexOf(extension.toLowerCase()) < 0) {
                    this.Utils.messageBox('上传模板只能是 xls、xlsx格式!', "error");
                    this.uploadTHint(false);
                    return false;
                }
                if (!isLt2M) {
                    this.Utils.messageBox('上传模板大小不能超过 10MB!', "error");
                    this.uploadTHint(false);
                    return false;
                }
                return extension && isLt2M;
            },
            // 上传错误
            uploadError(response, file, fileList) {
                this.uploadTHint(false);
                this.Utils.messageBox("上传失败，请重试！", "error");
            },
            DownloadFile: function () {
                this.Utils.export("omsapi/common/downloadtemplate?uploadFileType=700&fileName=地址导入.xlsx");
            },
            uploadTHint(value){
                if (value) {
                    this.loading = true;
                    this.updateText = "拼命上传中...";
                }
                else
                {
                    this.loading = false;
                    this.updateText = "导入";
                }
            }
        }
    }
</script>
<style scope>
    input[type="file"] {
        display: none;
    }

    .el-upload-list {
        width: 200px;
    }

    .el-select {
        width: 135px;
    }
</style>